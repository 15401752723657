import {
  MenuBar,
  MobileTopMenuBar,
  PreJoinScreens,
  ReconnectingNotification,
  RecordingNotifications,
  Room,
  useAppState,
  useHeight,
  useRoomState,
} from '@jaksmok/lovelanguage-common';
import { Hidden } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';

import logo from 'assets/360Live-192.png';
import clsx from 'clsx';
import React from 'react';
import lobbyImage from './assets/lobby-image.jpg';
import Colors from './Color';
import bgImage from './assets/bg.svg';

const useStyle = makeStyles((theme) => ({
  main: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    background: `${Colors.blue}`,
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '230%',
    fontFamily: 'campton-book',
    height: '100vh',
  },
  preJoinContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  whiteBackground: {
    // background: '#ffffff',
  },
  darkBackground: {
    // background: '#091c2b',
  },
}));

const CallScreen = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: theme.palette.background.dark,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

const Logo = styled('img')(({ theme }) => ({
  width: 120,
  height: 120,
}));

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: '380px',
  objectFit: 'cover',
  borderRadius: 8,
  objectPosition: '-80px ',
}));

const RoomInfoContainer = styled('div')(({ theme }) => ({
  width: '60%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexDirection: 'column',
  gap: 30,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: 50,
  },
}));

const GuestNameContainer = styled('div')(({ theme }) => ({
  height: 600,
  display: 'flex',
  justifyContent: 'center',
  padding: 30,
  maxWidth: 1100,
}));

const CoverImageContainer = styled('div')(({ theme }) => ({
  width: '40%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginLeft: 20,
}));

export default function App() {
  const classes = useStyle();
  const roomState = useRoomState();
  const { callStep } = useAppState();
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <main className={clsx(classes.main)} style={{ height }}>
      {roomState === 'disconnected' ? (
        <div className={clsx(classes.preJoinContainer)}>
          {callStep === 'guestNameStep' ? (
            <GuestNameContainer>
              <RoomInfoContainer>
                <Logo src={logo} alt="360Live logo" />
                <PreJoinScreens />
              </RoomInfoContainer>
              <Hidden smDown>
                <CoverImageContainer>
                  <Image src={lobbyImage} alt="lobbyImage" />
                </CoverImageContainer>
              </Hidden>
            </GuestNameContainer>
          ) : (
            <PreJoinScreens />
          )}
        </div>
      ) : (
        <CallScreen>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar />
        </CallScreen>
      )}
    </main>
  );
}
